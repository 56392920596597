import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Splash from "./pages/Splash";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ZodiacDetails from "./pages/ZodiacDetails";

const RoutesConfig = () => {
  return(
    <Router basename="/horoscope">
      <Routes>
        <Route path="/" element = {<Splash />} />
        <Route path="/home" element = {<Home />} />
        <Route path="/sign-in" element = {<Login />} />
        <Route path="/sign-up" element = {<SignUp />} />
        <Route path="/forgot-password" element = {<ForgotPassword />} />
        <Route path="/zodiac/:sign" element = {<ZodiacDetails />} />
      </Routes>
    </Router>
  )
}

export default RoutesConfig;