import RoutesConfig from './RoutesConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <RoutesConfig />
      <ToastContainer
        theme='colored'
        hideProgressBar
        closeButton = {false}
        autoClose = {3000}
        pauseOnFocusLoss = {false}
        pauseOnHover = {false}
        position='bottom-center'
      />
    </>
  );
}

export default App;
