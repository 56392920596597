import { motion } from "framer-motion"
import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom";
import { formattedLanguageItem } from "../Language";

const SignContainer = ({
  sign
}) => {

  const [show_anim, set_show_anim] = useState(false);

  const ref = useRef();

  const windowPosition = 110;

  const item_top = ref.current?.offsetTop;
  const item_width = ref.current?.offsetWidth;
  const window_width = window.outerWidth;
  const item_left = ref.current?.offsetLeft;

  const window_center_position = (window_width - item_width) / 2;

  const variants = {
    move : {
      top : windowPosition - item_top + 50,
      left : window_center_position - item_left,
      scale : 2,
      zIndex : 100
    }
  }

  const navigate = useNavigate();

  const navigateToSign = (sign) => {
    set_show_anim(true);
    window.scrollTo({ top : 0 })
    setTimeout(() => {
      localStorage.setItem("selected_sign", sign);
      navigate(`/zodiac/${sign}`, {
        state : {
          navigated_from_homepage : true
        }
      });
    }, 1000)
  }

  return(
    <div
      className="signs-container"
      // onClick={() => set_show_anim(true)}
      onClick={() => navigateToSign(sign.title)}
    >
      <motion.div
        className="sign-box d-flex align-items-center justify-content-center w-100 position-relative"
        ref={ref}

        variants={variants}

        animate = {show_anim ? 'move' : null}

        transition={{
          duration : 1,
          ease: "easeInOut"
        }}
      >
        {sign.logo}
      </motion.div>
      <div className="label text-center">
        {formattedLanguageItem(sign.title)}
      </div>
    </div>
  )
}

export default SignContainer;