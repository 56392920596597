import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as HoroscopeCircle } from "../static/images/horoscope-circle.svg";
import StarStockImage from "../static/images/star-stock-image.png";
import PageLoader from "../Components/PageLoader";
import { useEffect, useState } from "react";
import { check_login_status } from "../services/firebase";

const Splash = () => {

  const [is_loading, set_is_loading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top : 0 })
    check_login_status().then((is_logged_in) => {
      if (is_logged_in) {
        navigate('/home')
      }
      set_is_loading(false);
    })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <PageLoader
        show={is_loading}
      />
      <div className="site-container splash-container position-relative overflow-x-hidden">
        <img
          src={StarStockImage}
          alt=""
          className="bg-stars position-absolute"
        />
        <div className="d-flex justify-content-center horoscope-circle-container position-relative">
          {
            !is_loading &&
            <HoroscopeCircle />
          }
        </div>

        <div className="page-title text-center position-relative">
          Activate Your Band
        </div>
        <div className="page-subtitle text-center position-relative">
          Choose option below to proceed
        </div>

        <div className="glass-box d-flex flex-column position-relative">
          <Link to='/sign-up' className="d-block">
            <button className="button-filled w-100">
              Sign up
            </button>
          </Link>
          <Link to='/sign-in' className="d-block">
            <button className="button-bordered w-100">
              Sign in
            </button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Splash;