import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBQMA_dlKKEazmqOkHsCLDkA31g9yGiOXM",
  authDomain: "horoscope-app-lynklets.firebaseapp.com",
  projectId: "horoscope-app-lynklets",
  storageBucket: "horoscope-app-lynklets.appspot.com",
  messagingSenderId: "237180697590",
  appId: "1:237180697590:web:7267ffe1467486981525d7",
  measurementId: "G-6HFYJ5N4JQ"
};

// Initialize Firebase
export const firebase_app = initializeApp(firebaseConfig);
export const firebase_auth = getAuth(firebase_app);
export const firebase_storage = getStorage(firebase_app);
export const firestore_db = getFirestore(firebase_app);

export const check_login_status = () => {

  return new Promise((resolve) => {
    onAuthStateChanged(firebase_auth, (user) => {
      if(user){
        resolve(true);
      }else{
        resolve(false);
      }
    });
  })
}