import HoroscopeCircle from "../static/images/horoscope-circle.svg";

const PageLoader = ({
  show
}) => {
  return(
    <div
      className={`page-loader ${show ? "show" : ""} fixed-top h-100 w-100 d-flex align-items-center justify-content-center`}
    >
      <img
        src={HoroscopeCircle}
        height={200}
        width={200}
        className="rotate"
        alt=""
      />
    </div>
  )
}

export default PageLoader;