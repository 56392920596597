import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CaretLeft } from "../static/icons/caret-left.svg"
import { ReactComponent as Aries } from "../static/images/signs/Aries.svg";
import { ReactComponent as Taurus } from "../static/images/signs/Taurus.svg";
import { ReactComponent as Gemini } from "../static/images/signs/Gemini.svg";
import { ReactComponent as Cancer } from "../static/images/signs/Cancer.svg";
import { ReactComponent as Leo } from "../static/images/signs/Leo.svg";
import { ReactComponent as Virgo } from "../static/images/signs/Virgo.svg";
import { ReactComponent as Libra } from "../static/images/signs/Libra.svg";
import { ReactComponent as Scorpio } from "../static/images/signs/Scorpio.svg";
import { ReactComponent as Sagittarius } from "../static/images/signs/Sagittarius.svg";
import { ReactComponent as Capricon } from "../static/images/signs/Capricon.svg";
import { ReactComponent as Aquarius } from "../static/images/signs/Aquarius.svg";
import { ReactComponent as Pisces } from "../static/images/signs/Pisces.svg";
import { ReactComponent as Logo } from "../static/images/horoscope-circle.svg";
// import Footer from "../Components/Footer";
import PageLoader from "../Components/PageLoader";
import { useEffect, useState } from "react";
import { check_login_status, firebase_storage, firestore_db } from "../services/firebase";
import { ReactComponent as CaretDown } from "../static/icons/caret-down.svg";
import { ReactComponent as ShoppingCart } from "../static/icons/shopping-cart.svg";
import { getBlob, listAll, ref } from "firebase/storage";
import { LANGUAGES } from "../constants";
import moment from "moment";
import { doc, getDoc } from "firebase/firestore"; 
import { formattedLanguageItem } from "../Language";

const SignImages = {
  "Aries": <Aries className="sign" />,
  "Taurus": <Taurus className="sign" />,
  "Gemini": <Gemini className="sign" />,
  "Cancer": <Cancer className="sign" />,
  "Leo": <Leo className="sign" />,
  "Virgo": <Virgo className="sign" />,
  "Libra": <Libra className="sign" />,
  "Scorpio": <Scorpio className="sign" />,
  "Sagittarius": <Sagittarius className="sign" />,
  "Capricon": <Capricon className="sign" />,
  "Aquarius": <Aquarius className="sign" />,
  "Pisces": <Pisces className="sign" />
}
const ZodiacDetails = () => {

  const [is_loading, set_is_loading] = useState(true);
  const [english_data, set_english_data] = useState('');
  const [spanish_data, set_spanish_data] = useState('');
  const [is_fetching_data, set_is_fetching_data] = useState(true);
  const [cart_link, set_cart_link] = useState('');

  const params = useParams();

  const sign = params.sign;

  const navigate = useNavigate();
  const location = useLocation();

  const [show_language_dropdown, set_show_language_dropdown] = useState(false);
  const [selected_language, set_selected_language] = useState(localStorage.getItem("selected_language") || 'Eng');

  const handleSelectLanguage = (language) => {
    set_selected_language(language);
    set_show_language_dropdown(false);
    localStorage.setItem("selected_language", language)
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })

    const cart_ref = doc(firestore_db, "cart", "Link");
    getDoc(cart_ref).then((docSnap) => {

      if(docSnap.exists()){
        const data = docSnap.data();

        set_cart_link(data.link)
      }
    })

    check_login_status().then((is_logged_in) => {
      if (!is_logged_in) {
        navigate('/sign-in');
      }
      else{
        getData();
      }
      set_is_loading(false);
    })
    // eslint-disable-next-line
  }, [])


  const getData = async () => {
    const ENGLISH_LIST_REF = ref(firebase_storage, `${sign}/${LANGUAGES.ENGLISH}`);
    const SPANISH_LIST_REF = ref(firebase_storage, `${sign}/${LANGUAGES.SPANISH}`);

    const englishRes = await listAll(ENGLISH_LIST_REF);
    const spanishRes = await listAll(SPANISH_LIST_REF);

    const english_files = englishRes.items.map(item => item.name);
    const spanish_files = spanishRes.items.map(item => item.name);

    const english_file_name = getLastFileName(english_files);
    const spanish_file_name = getLastFileName(spanish_files);
    
    if(english_file_name){
      const englishData = await getFileData(`${sign}/${LANGUAGES.ENGLISH}/${english_file_name}`);
      set_english_data(englishData);
    }

    if(spanish_file_name){
      const spanishData = await getFileData(`${sign}/${LANGUAGES.SPANISH}/${spanish_file_name}`);
      set_spanish_data(spanishData);
    }

    set_is_fetching_data(false);
  }

  const getLastFileName = (files) => {
    if(files.length){
      let file_found = false;
      let file_name = null;
      let days_count = 0;

      if(files.length === 1){
        return files[0];
      }
      while(!file_found && days_count < files.length){
        const moment_obj = moment().subtract(days_count, 'day');
  
        const date = moment_obj.get('date');
        const month = moment_obj.get('month') + 1;
        const year = moment_obj.get('year');

        const fileName = `${date}-${month}-${year}.txt`;

        if(files.includes(fileName)){
          file_found = true;
          file_name = fileName;
        }else{
          days_count++;
        }
      }

      return file_name;
    }else{
      return null
    }
  }

  const getFileData = async (file_path) => {
    const file_path_ref = ref(firebase_storage, file_path);

    const file_url = await getBlob(file_path_ref);

    const response = await file_url.text();

    return response;
  }

  const description = selected_language === "Spa" ? spanish_data : english_data;

  const navigated_from_homepage = location.state && location.state.navigated_from_homepage;

  return (
    <>
      {
        !navigated_from_homepage &&
        <PageLoader
          show={is_loading}
        />
      }
      <div className="site-container zodiac-details-container">

        <div className="top-row">
          <Link
            to={'/home'}
            className="top-row-button square d-flex align-items-center justify-content-center"
            onClick={() => localStorage.removeItem("selected_sign")}
          >
            <CaretLeft />
          </Link>

          <div className="title d-flex align-items-center justify-content-center">
            {/* Sign */}
          </div>

          {
            Boolean(cart_link) ?
            <a
              className="top-row-button square d-flex align-items-center justify-content-center"
              href={cart_link}
              target="_blank"
              rel="noreferrer noopener"
            >
              <ShoppingCart
                width={24}
              />
            </a> :
            <div
              className="top-row-button square d-flex align-items-center justify-content-center"
            >
              <ShoppingCart
                width={24}
              />
            </div>
          }

          <div className="position-relative language-dropdown-container">

            <div
              className="top-row-button d-flex align-items-center justify-content-center position-relative"
              onClick={() => set_show_language_dropdown(!show_language_dropdown)}
            >
              {selected_language} <CaretDown />

            </div>

            <div className={`language-dropdown position-absolute ${show_language_dropdown ? 'show' : ''}`}>
              <div
                className={`language d-flex align-items-center ${selected_language === "Eng" ? "active" : ""}`}
                onClick={() => handleSelectLanguage("Eng")}
              >
                English
              </div>
              <div
                className={`language d-flex align-items-center ${selected_language === "Spa" ? "active" : ""}`}
                onClick={() => handleSelectLanguage("Spa")}
              >
                Spanish
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center sign-container mx-auto">
          {
            SignImages[sign]
          }
        </div>


        <div
          className="title text-center position-relative"
        >
          {formattedLanguageItem(sign)}
        </div>
        <div
          className="sub-title text-center position-relative"
        >
          {formattedLanguageItem("Today")}
        </div>

        {
          is_fetching_data ?
          <div className="d-flex justify-content-center">
            <Logo
              height={150}
              width={150}
              className="rotate"
            />
          </div> :
          <div
            className="description position-relative"
          >
            {description}
          </div>
        }

        {/* <Footer /> */}
      </div>
    </>
  )
}

export default ZodiacDetails;