import { useEffect, useState } from "react";
import { ReactComponent as HoroscopeCircle } from "../static/images/horoscope-circle.svg";
import StarStockImage from "../static/images/star-stock-image.png";
import { Link, useNavigate } from "react-router-dom";
import { check_login_status, firebase_auth } from "../services/firebase";
import PageLoader from "../Components/PageLoader";
import validator from "validator";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";

const ForgotPassword = () => {

  const [email, set_email] = useState("");
  const [is_loading, set_is_loading] = useState(true);
  const [is_sending, set_is_sending] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top : 0 })
    check_login_status().then((is_logged_in) => {
      if (is_logged_in) {
        navigate('/home')
      }
      set_is_loading(false);
    })
    // eslint-disable-next-line
  }, [])

  const sendForgotPasswordMail = () => {
    set_is_sending(true);
    sendPasswordResetEmail(firebase_auth, email).then(() => {
      toast("Password reset mail sent successfully !", { type: "success" })
    }).catch((error) => {
      toast("Error while sending password reset mail", { type: "error" })
      console.log(error);
    }).finally(() => {
      set_is_sending(false)
    })
  }

  return (
    <>
      <PageLoader
        show={is_loading}
      />
      <div className="site-container forgot-password-container position-relative overflow-x-hidden">
        <img
          src={StarStockImage}
          alt=""
          className="bg-stars position-absolute"
        />
        <div className="d-flex justify-content-center horoscope-circle-container position-relative">
          <HoroscopeCircle
            className="rotate"
          />
        </div>

        <div className="page-title text-center position-relative">
          Forgot Password
        </div>
        <div className="page-subtitle text-center position-relative">
          Enter your email for verifcation
        </div>

        <div className="glass-box position-relative">
          <div className="d-flex flex-column input-column">
            <div className="input-container">
              <label>
                Email
              </label>
              <input
                type="text"
                className="input"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => set_email(e.target.value)}
              />
            </div>
          </div>

          <button
            className="button-filled w-100"
            disabled = {
              !validator.isEmail(email) ||
              is_sending
            }
            onClick={sendForgotPasswordMail}
          >
            {is_sending ? "Sending . . ." : "Send email"}
          </button>
        </div>

        <div className="text-center footer-text">
          Have an account already? <Link to='/sign-in' className="form-link"> Login here! </Link>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword;