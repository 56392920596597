import { useEffect, useState } from "react";
import PasswordInput from "../Components/PasswordInput";
import { ReactComponent as HoroscopeCircle } from "../static/images/horoscope-circle.svg";
import StarStockImage from "../static/images/star-stock-image.png";
import { Link, useNavigate } from "react-router-dom";
import { check_login_status, firebase_auth } from "../services/firebase";
import validator from "validator";
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import PageLoader from "../Components/PageLoader";

const Login = () => {

  const [email, set_email] = useState("");
  const [password, set_password] = useState("");
  const [is_logging_in, set_is_logging_in] = useState(false);
  const [is_loading, set_is_loading] = useState(true);

  const navigate = useNavigate();

  const handleLogin = () => {
    set_is_logging_in(true);
    signInWithEmailAndPassword(firebase_auth, email, password).then(() => {
      navigate("/home");
    }).catch(() => {
      toast("Invalid username or password", { type: "error" })
    }).finally(() => {
      set_is_logging_in(false);
    })
  }

  useEffect(() => {
    window.scrollTo({ top : 0 })
    check_login_status().then((is_logged_in) => {
      if (is_logged_in) {
        navigate('/home')
      }
      set_is_loading(false);
    })
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <PageLoader
        show={is_loading}
      />
      <div className="site-container sign-in-container position-relative overflow-x-hidden">
        <img
          src={StarStockImage}
          alt=""
          className="bg-stars position-absolute"
        />
        <div className="d-flex justify-content-center horoscope-circle-container position-relative">
          <HoroscopeCircle
            className="rotate"
          />
        </div>

        <div className="page-title text-center position-relative">
          Sign in
        </div>
        <div className="page-subtitle text-center position-relative">
          Login to your Account
        </div>

        <div className="glass-box position-relative">
          <div className="d-flex flex-column input-column">
            <div className="input-container">
              <label>
                Email
              </label>
              <input
                type="text"
                className="input"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => set_email(e.target.value)}
              />
            </div>

            <div className="input-container">
              <label>
                Password
              </label>

              <PasswordInput
                placeholder="Enter your password"
                value={password}
                onChange={(e) => set_password(e.target.value)}
              />
            </div>

            <div className="d-flex justify-content-center">
              <Link to='/forgot-password' className="form-link">
                Forgot Password?
              </Link>
            </div>
          </div>

          <button
            className="button-filled w-100"
            disabled={
              !validator.isEmail(email) ||
              !Boolean(password) ||
              is_logging_in
            }
            onClick={handleLogin}
          >
            {
              is_logging_in ? "Signing in . . ." : "Sign in"
            }
          </button>
        </div>

        <div className="text-center footer-text">
          Don't have an account, <Link to='/sign-up' className="form-link"> Signup here! </Link>
        </div>
      </div>
    </>
  )
}

export default Login;