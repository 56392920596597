import { ReactComponent as Aries } from "../static/images/signs/Aries.svg";
import { ReactComponent as Taurus } from "../static/images/signs/Taurus.svg";
import { ReactComponent as Gemini } from "../static/images/signs/Gemini.svg";
import { ReactComponent as Cancer } from "../static/images/signs/Cancer.svg";
import { ReactComponent as Leo } from "../static/images/signs/Leo.svg";
import { ReactComponent as Virgo } from "../static/images/signs/Virgo.svg";
import { ReactComponent as Libra } from "../static/images/signs/Libra.svg";
import { ReactComponent as Scorpio } from "../static/images/signs/Scorpio.svg";
import { ReactComponent as Sagittarius } from "../static/images/signs/Sagittarius.svg";
import { ReactComponent as Capricon } from "../static/images/signs/Capricon.svg";
import { ReactComponent as Aquarius } from "../static/images/signs/Aquarius.svg";
import { ReactComponent as Pisces } from "../static/images/signs/Pisces.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { check_login_status } from "../services/firebase";
import PageLoader from "../Components/PageLoader";
import SignContainer from "../Components/SignContainer";
import { ReactComponent as Cross } from "../static/icons/cross.svg";
import { ReactComponent as QuestionMark } from "../static/icons/question-mark.svg";
import { formattedLanguageItem } from "../Language";

const SIGNS = [
  {
    title: "Aries",
    logo: <Aries />
  },
  {
    title: "Taurus",
    logo: <Taurus />
  },
  {
    title: "Gemini",
    logo: <Gemini />
  },
  {
    title: "Cancer",
    logo: <Cancer />
  },
  {
    title: "Leo",
    logo: <Leo />
  },
  {
    title: "Virgo",
    logo: <Virgo />
  },
  {
    title: "Libra",
    logo: <Libra />
  },
  {
    title: "Scorpio",
    logo: <Scorpio />
  },
  {
    title: "Sagittarius",
    logo: <Sagittarius />
  },
  {
    title: "Capricon",
    logo: <Capricon />
  },
  {
    title: "Aquarius",
    logo: <Aquarius />
  },
  {
    title: "Pisces",
    logo: <Pisces />
  },
]

const Home = () => {

  const [is_loading, set_is_loading] = useState(true);
  const [show_popup, set_show_popup] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    window.scrollTo({ top: 0 })

    check_login_status().then((is_logged_in) => {
      if (is_logged_in) {
        const selected_sign = localStorage.getItem("selected_sign");

        if (selected_sign) {
          navigate(`/zodiac/${selected_sign}`)
        }
      }
      else {
        navigate('/sign-in')
      }
      set_is_loading(false);
    })
    // eslint-disable-next-line
  }, [])

  const selected_language = localStorage.getItem("selected_language") || 'Eng';

  const english_description = `Figuring out your zodiac sign is simple! Just match your birth date with one of the following signs. Whatever range it falls in is your sign!

    Aries: March 21 - April 19
    Taurus: April 20 - May 20
    Gemini: May 21 - June 20
    Cancer: June 21 - July 22
    Leo: July 23 - August 22
    Virgo: August 23 - September 22
    Libra: September 23 - October 22
    Scorpio: October 23 - November 21
    Sagittarius: November 22 - December 21
    Capricorn: December 22 - January 19
    Aquarius: January 20 - February 18
    Pisces: February 19 - March 20`;

  const spanish_description = `¡Descubrir tu signo zodiacal es muy sencillo! Solo tienes que comparar tu fecha de nacimiento con uno de los siguientes signos. ¡El rango en el que caiga es tu signo!

    Aries: 21 de marzo - 19 de abril
    Tauro: 20 de abril - 20 de mayo
    Géminis: 21 de mayo - 20 de junio
    Cáncer: 21 de junio - 22 de julio
    Leo: 23 de julio - 22 de agosto
    Virgo: 23 de agosto - 22 de septiembre
    Libra: 23 de septiembre - 22 de octubre
    Escorpio: 23 de octubre - 21 de noviembre
    Sagitario: 22 de noviembre - 21 de diciembre
    Capricornio: 22 de diciembre - 19 de enero
    Acuario: 20 de enero - 18 de febrero
    Piscis: 19 de febrero - 20 de marzo`;
  
  const how_to_find_sign_description = selected_language === 'Spa' ? spanish_description : english_description

  return (
    <>
      <PageLoader
        show={is_loading}
      />
      <div className="home-container site-container">

        <div className="top-row">
          <div
            className="top-row-button square d-flex align-items-center justify-content-center"
            onClick={() => set_show_popup(true)}
          >
            <QuestionMark
              height={18}
            />
          </div>

          <div className="title d-flex align-items-center justify-content-center">
            Choose Your Sign
          </div>
        </div>
        <div className="signs-row">
          {
            SIGNS.map((sign) => {
              return (
                <SignContainer
                  key={sign.title}
                  sign={sign}
                >
                </SignContainer>
              )
            })
          }
        </div>

      </div>

      <div className={`popup-container ${show_popup ? 'show' : ''} fixed-top h-100 w-100 d-flex align-items-center`}>
        <div className="popup position-relative">

          <div
            className="popup-close-button d-flex align-items-center justify-content-center position-absolute"
            onClick={() => set_show_popup(false)}
          >
            <Cross />
          </div>
          <div className="popup-title">
            {formattedLanguageItem("How to find your Zodiac sign?")}
          </div>

          <div className="popup-description">
            <p className="mb-0">
              {how_to_find_sign_description}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home;