import { ReactComponent as Eye } from "../static/icons/eye.svg";
import { ReactComponent as EyeClose } from "../static/icons/eye-close.svg";
import { useState } from "react";

const PasswordInput = ({
  placeholder,
  onChange,
  value
}) => {

  const [ show_password, set_show_password ] = useState(false);

  return(
    <div className="d-flex password-input-container align-items-center">
      <input
        type={show_password ? "text" : "password"}
        className="flex-grow-1"
        placeholder = {placeholder}
        onChange={onChange}
        value = {value}
      />
      
      {
        show_password ? 
        <div onClick={() => set_show_password(false)}>
          <Eye />
        </div> :
        <div onClick={() => set_show_password(true)}>
          <EyeClose />
        </div>
      }
    </div>
  )
}

export default PasswordInput;