import { useEffect, useState } from "react";
import PasswordInput from "../Components/PasswordInput";
import { ReactComponent as HoroscopeCircle } from "../static/images/horoscope-circle.svg";
import StarStockImage from "../static/images/star-stock-image.png";
import { Link, useNavigate } from "react-router-dom";
import validator from "validator";
import { toast } from "react-toastify";
import { check_login_status, firebase_auth } from "../services/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import PageLoader from "../Components/PageLoader";

const SignUp = () => {

  const [email, set_email] = useState("");
  const [password, set_password] = useState("");
  const [confirm_password, set_confirm_password] = useState("");
  const [is_saving, set_is_saving] = useState(false);
  const [is_loading, set_is_loading] = useState(true);

  const navigate = useNavigate();

  const isValid = () => {
    return (
      validator.isEmail(email) &&
      Boolean(password) &&
      Boolean(confirm_password)
    )
  }

  const handleSignUp = () => {
    if (password === confirm_password) {
      set_is_saving(true);

      createUserWithEmailAndPassword(firebase_auth, email, password).then(() => {
        navigate('/home');
      }).catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          toast("Email address already in use !", { type: "error" })
        }
        else {
          toast("Error while signing up", { type: "error" })
        }
      }).finally(() => {
        set_is_saving(false);
      })
    } else {
      toast("Password and confirm password are not same", { type: "error" })
    }
  }

  useEffect(() => {
    window.scrollTo({ top : 0 })
    check_login_status().then((is_logged_in) => {
      if (is_logged_in) {
        navigate('/home')
      }
      set_is_loading(false);
    })
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <PageLoader
        show={is_loading}
      />
      <div className="site-container sign-up-container position-relative overflow-x-hidden">
        <img
          src={StarStockImage}
          alt=""
          className="bg-stars position-absolute"
        />
        <div className="d-flex justify-content-center horoscope-circle-container position-relative">
          <HoroscopeCircle
            className="rotate"
            height={177}
            width={177}
          />
        </div>

        <div className="page-title text-center position-relative">
          Sign up
        </div>
        <div className="page-subtitle text-center position-relative">
          Create your account
        </div>

        <div className="glass-box position-relative">
          <div className="d-flex flex-column input-column">
            <div className="input-container">
              <label>
                Email
              </label>
              <input
                type="text"
                className="input"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => set_email(e.target.value)}
              />
            </div>

            <div className="input-container">
              <label>
                Password
              </label>

              <PasswordInput
                placeholder="Enter your password"
                value={password}
                onChange={(e) => set_password(e.target.value)}
              />
            </div>

            <div className="input-container">
              <label>
                Confirm Password
              </label>

              <PasswordInput
                placeholder="Enter your password"
                value={confirm_password}
                onChange={(e) => set_confirm_password(e.target.value)}
              />
            </div>
          </div>

          <button
            className="button-filled w-100"
            disabled={
              !isValid() ||
              is_saving
            }
            onClick={handleSignUp}
          >
            {is_saving ? 'Signing up . . .' : 'Sign up'}
          </button>
        </div>

        <div className="text-center footer-text">
          Have an account already? <Link to='/sign-in' className="form-link"> Login here! </Link>
        </div>
      </div>
    </>
  )
}

export default SignUp;